'use client';

import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useSearchParams } from 'next/navigation';

export default function LoginPage() {
  console.info('LoginPage');
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  useEffect(() => {
    console.info('LoginPage useEffect');

    const invitation = searchParams.get('invitation');
    const organization = searchParams.get('organization');

    console.log('LoginPage useEffect invitation', invitation);
    console.log('LoginPage useEffect organization', organization);

    console.log('NEXT_PUBLIC_AUTH0_CLIENT_ID', process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID);
    console.log('NEXT_PUBLIC_AUTH0_DOMAIN', process.env.NEXT_PUBLIC_AUTH0_DOMAIN);
    console.log('NEXT_PUBLIC_VERCEL_ENV', process.env.NEXT_PUBLIC_VERCEL_ENV);
    console.log('NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL', process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL);
    console.log('NEXT_PUBLIC_VERCEL_URL', process.env.NEXT_PUBLIC_VERCEL_URL);
    // Only proceed with Auth0 login if we have either an invitation or organization
    if (invitation || organization) {
      // Construct Auth0 authorization URL with required parameters
      const baseUrl =
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
          ? process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL
          : process.env.NEXT_PUBLIC_VERCEL_URL;

      const protocol = baseUrl?.includes('localhost') ? 'http' : 'https';

      const authParams = new URLSearchParams({
        response_type: 'code',
        client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!,
        redirect_uri: `${protocol}://${baseUrl}`,
        ...(invitation && { invitation }),
        ...(organization && { organization }),
      });
      try {
        posthog.capture('invitation_login_attempted', {
          auth0_domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
          invitation: invitation ? `${invitation.slice(0, 5)}...` : undefined,
          organization,
          redirect_uri: `${protocol}://${baseUrl}`,
          client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!,
        });
      } catch (error) {
        console.warn('Error capturing event:', error);
      }

      // Redirect to Auth0 authorization endpoint
      window.location.href = `https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/authorize?${authParams.toString()}`;
    }
  }, [searchParams, posthog]);

  // Show error if required environment variables are missing
  if (!process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || !process.env.NEXT_PUBLIC_AUTH0_DOMAIN) {
    window.location.replace('/error');
    return null;
  }

  // Show loading state while redirect happens
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <h1 className="text-xl font-semibold mb-2">Redirecting to login...</h1>
        <p className="text-gray-600 dark:text-gray-400">Please wait while we redirect you to the login page.</p>
      </div>
    </div>
  );
}
